import React from "React";
import Layout from "../components/Global/Layout";
import { graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"

import Team from "../components/Global/Team";

import "../assets/styles/teampage.scss"


export const query = graphql`
  query Team {
      allWpTeam {
        nodes {
          id
          teamContentMeta {
            teamName
            teamPosition
            teamImage {
              id
              remoteFile {
                id
                childImageSharp {
                  id
                  fluid(maxWidth: 206, maxHeight: 206) {
                    base64
                    tracedSVG
                    srcWebp
                    srcSetWebp
                    originalImg
                    originalName
                  }
                }
              }
            }
          }
        }
      }
  }
`

export default (data) => {

    const current = "team"

    return(
        <>
        <Layout current={current}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Our amazing team - Develescope</title>
          <meta name="description" content="Our company is led by a value-driven team of creatives. Our leaders are diverse executives who came together to create new imaginative solutions, that work for our clients." />
        </Helmet>
        <section className="section team-page">
            <div className="container">
                <div className="top-txt">
                    <h1>Why we have the best team</h1>
                    <p>Our company is led by a value-driven team of creatives. Our leaders are diverse executives who came together to create new imaginative solutions, that work for our clients.</p>
                </div>
                <div className="team-nav">
                    <h4>Our Core Team</h4>
                </div>
                <div className="team-whole">
                    <h3>Meet our members</h3>
                    <Team team={data.data.allWpTeam.nodes}/>
                    <h4>+30 more professionals eager to make your next project amazing!</h4>
                </div>
            </div>
        </section>
        </Layout>
        </>
    )
}